import React from "react";
import "./Home.css";
import CollectioOne from "../../Assets/collection-item1.jpg";
import CollectioTwo from "../../Assets/collection-item2.jpg";
import CollectionThree from "../../Assets/collection-item3.jpg";

export default function Collectionsbanner() {
  return (
    <div className="main_collection">
      <div className="collection_all">
        <div className="collection_first">
          <img src={CollectioOne} alt="logo" />
          <div className="content_collection">
            <span>COLLECTION NAME</span> <br></br>
            <label className="collection_heading">Street Wear</label>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
              lorem eros, porta in ante eget
            </p>
            <a>
              Shop The Collection
              <svg
                width="20"
                height="20"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m12.563 5.25 6.75 6.75-6.75 6.75"></path>
                <path d="M18.375 12H4.687"></path>
              </svg>
            </a>
          </div>
        </div>
        <div className="collection_second">
          <div className="collection_second_one">
            {/* <img src={CollectioTwo} alt="logo" /> */}
            <div>
              <span>COLLECTION NAME</span> <br></br>
              <label>Street Wear</label>
              <a>
                Shop The Collection
                <svg
                  width="20"
                  height="20"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m12.563 5.25 6.75 6.75-6.75 6.75"></path>
                  <path d="M18.375 12H4.687"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="collection_second_two">
            {/* <img src={CollectionThree} alt="logo" /> */}
            <div>
              <span>COLLECTION NAME</span> <br></br>
              <label>Street Wear</label>
              <a>
                Shop The Collection
                <svg
                  width="20"
                  height="20"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m12.563 5.25 6.75 6.75-6.75 6.75"></path>
                  <path d="M18.375 12H4.687"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
