import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import BannerImage1 from "../../Assets/banner1.jpg";
import BannerImage2 from "../../Assets/banner2.jpg";
import "./Home.css";
import FeatureProduct from "./FeatureProduct";
import Collectionsbanner from "./Collectionsbanner";
export default function Home() {
  const bannerContent = [
    {
      id: 1,
      image: BannerImage1,
      title: "Summer Collection",
      button: "Shop Now",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras malesuada augue neque, sed scelerisque ligula interdum sit amet. Curabitur ornare mattis eleifend. Proin in aliquam ex.",
    },
    {
      id: 2,
      image: BannerImage2,
      title: "Casual Collection",
      button: "Shop Now",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras malesuada augue neque, sed scelerisque ligula interdum sit amet. Curabitur ornare mattis eleifend. Proin in aliquam ex.",
    },
  ];

  return (
    <div className="carousel_component">
      <Carousel
        autoPlay
        interval="2000"
        transitionTime="1000"
        showThumbs={false}
      >
        {bannerContent.map((banner, index) => {
          return (
            <>
              <div key={index}>
                <img src={banner.image} alt="banner" />
                <div className="banner_content">
                  <h1>{banner.title}</h1>
                  <p>{banner.content}</p>
                  <div className="carousel_button">
                    <button>
                      {banner.button}
                      <svg
                        width="20"
                        height="20"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m12.563 5.25 6.75 6.75-6.75 6.75"></path>
                        <path d="M18.375 12H4.687"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </Carousel>
      <FeatureProduct />  
      <Collectionsbanner />
    </div>
  );
}
