import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Home.css";

export default function FeatureProduct() {
  const [productDetails, setProductDetails] = useState([]);

  const fetchData = async () => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in/wp-json/custom-wc/v1/vendor-products-by-tags?vendor_id=9&tags[]=pro-aachi&page=1&per_page=4`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      console.log(response.data.products);
      setProductDetails(response.data.products)
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="feature_product">
      <div className="products_header">
        <span>
          <b>Feature Products</b>
        </span>
        <h3>View all Products</h3>
      </div>
      <div className="feature_content">
        {productDetails.map((pro, index) => {
          return (
            <div key={index} className="product_content">
             <div className="pro_image">
             <img src={pro.image} alt="pro" height="200px" />
             </div>
              <span>{pro.name}</span>
              <p>${pro.regular_price}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
